import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { Campaign } from "../utilities/backend/client";
import { currency_format } from "../utilities/helpers/currency";
import { getUrlBlobContainerImage } from "../resources/imageConstants";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  borderColor: "#828282",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #828282",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#45B08A",
  },
}));
interface CampaignCardProps {
  campaign: Campaign | undefined;
  investedAmount: string | undefined;
  investedDate: string | undefined;
  status: string | undefined;
}

const CampaignCard: React.FC<CampaignCardProps> = ({
  campaign,
  investedAmount,
  investedDate,
  status,
}) => {
  const barValue =
    (Number(campaign?.currentBalance) * 100) / Number(campaign?.target);

  const imageFileName = campaign?.tileImageFileName || campaign?.imageFileName;

  const maxLength = 170;

  const description = campaign?.description || "";
  const truncatedDescription =
    description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;

  return (
    <Card sx={{ borderRadius: "16px" }}>
      <CardMedia
        sx={{ height: 250, borderRadius: "16px 16px 0 0", maxWidth: "362px" }}
        title={campaign?.name!}
        component="img"
        src={getUrlBlobContainerImage(imageFileName!)}
      />
      <CardContent sx={{ width: 330 }}>
        <Typography
          gutterBottom
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 24,
            fontWeight: 700,
            color: "#191919",
            lineHeigh: "28.8px",
            maxHeight: "40px",
            overflow: "hidden",
          }}
        >
          {campaign?.name}
        </Typography>
        <p
          dangerouslySetInnerHTML={{
            __html: truncatedDescription ? truncatedDescription : "",
          }}
          style={
            {
              fontFamily: "Ubuntu",
              fontSize: 14,
              fontWeight: 400,
              color: "#656565",
              lineHeight: "17.09px",
              maxHeight: "87px",
              minHeight: "87px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            } as React.CSSProperties
          }
        />
        {investedAmount && status === "approved" && (
          <Typography
            gutterBottom
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 24,
              fontWeight: 700,
              color: "#191919",
              lineHeigh: "28.8px",
            }}
          >
            You invested {currency_format(Number(investedAmount))}
          </Typography>
        )}
        {investedAmount && status === "pending" && (
          <Typography
            gutterBottom
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 24,
              fontWeight: 700,
              color: "#F29339",
              lineHeigh: "28.8px",
            }}
          >
            {currency_format(Number(investedAmount))} Investment Pending
          </Typography>
        )}

        {investedDate && (
          <Typography
            gutterBottom
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 14,
              fontWeight: 400,
              color: "#656565",
              lineHeigh: "16.09px",
            }}
          >
            {new Date(investedDate).toLocaleDateString("us")}
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={barValue > 100 ? 100 : barValue}
          />
        </Box>
        <Typography
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 26,
            fontWeight: 700,
            color: "#191919",
            lineHeigh: "29.87px",
            pt: "5px",
          }}
        >
          {campaign?.currentBalance === null
            ? "$0"
            : currency_format(Number(campaign?.currentBalance))}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 16,
            fontWeight: 400,
            color: "#656565",
            lineHeigh: "22px",
          }}
        >
          of a {currency_format(Number(campaign?.target))} goal
        </Typography>
        <Typography
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 16,
            fontWeight: 400,
            color: "#191919",
            lineHeigh: "22px",
            pt: "10px",
          }}
        >
          {campaign?.numberOfInvestors ? campaign?.numberOfInvestors : 0}{" "}
          {campaign?.numberOfInvestors === 1 ? "Investor" : "Investors"}
        </Typography>
        {/* <Box sx={{ flexGrow: 1 }}>
          <div dangerouslySetInnerHTML={{ __html: campaign?.goalMeter as string }}></div>
        </Box> */}
      </CardContent>
      {!investedDate && investedAmount && (
        <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions>
      )}
    </Card>
  );
};

export default CampaignCard;
