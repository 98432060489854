import {
  AddFollowingRequestDto,
  ApiException,
  DeleteRequestDto,
  FollowingRequestClient,
  TokenDto,
  UserDetailDto,
  UsersClient,
} from "../../../utilities/backend/client";
import { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import useHandleApiError from "../../../utilities/backend/Auth/useHandleApiError";
import {
  getUrlBlobContainerImage,
  defaultImage,
} from "../../../resources/imageConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";

const Stack1 = styled(Stack)(({ theme }) => ({
  width: "800px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Ttt = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 400,
  color: "#656565",
  marginTop: "38px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
  },
}));

const Button1 = styled(Button)(({ theme }) => ({
  maxHeight: "40px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0px !important",
  },
}));

const UserDetail = () => {
  const { id } = useParams();
  const userClient = new UsersClient();
  const [user, setUser] = useState<UserDetailDto>();
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const requestClient = new FollowingRequestClient();
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);
  const [requestChanger, setRequestChanger] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success");
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>("");
  const navigate = useNavigate();

  const loadUser = () => {
    setLoading(true);
    if (id) {
      userClient
        .users_GetUserByName(id, token)
        .then((response) => {
          setUser(response);
          if (response?.isOwner) {
            navigate("/settings");
          }
          setLoading(false);
        })
        .catch((error) => {
          setApiError(error);
          setLoading(false);
        });
    }
  };

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  useEffect(() => {
    loadUser();
  }, [requestChanger]);

  function followItem() {
    if (!token.token) {
      const followingRequestUserId = user?.id;

      localStorage.setItem(
        "followingRequestUserId",
        JSON.stringify(followingRequestUserId)
      );

      navigate("/register");
      return;
    }

    let data: AddFollowingRequestDto = {
      requestOwnerToken: token.token,
    };

    data.userToFollowId = user?.id;
    data.groupToFollowId = null;

    requestClient
      .followingRequest_Create_Request(data)
      .then(() => {
        setRequestChanger(!requestChanger);
        setLoading(true);
        setAlertSeverity("success");
        setNotificationText("You start following " + user?.userName);
        setNotificationShow(true);
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  function unfollowItem() {
    let data: DeleteRequestDto = {
      requestOwnerToken: token.token,
    };

    data.followedUserId = user?.id;
    data.followedGroupId = null;

    requestClient
      .followingRequest_Delete_Request(data)
      .then(() => {
        setRequestChanger(!requestChanger);
        setLoading(true);
        setAlertSeverity("error");
        setNotificationText("You stop following " + user?.userName);
        setNotificationShow(true);
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  return (
    <>
      {apiError?.status === 404 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            margin: 0,
          }}
        >
          <Stack spacing={0} sx={{ p: "0 18px", textAlign: "center" }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 32,
                fontWeight: 700,
                color: "#060A25",
              }}
            >
              Sorry profile of this user is hidden or not found
            </Typography>
          </Stack>
        </Box>
      ) : (
        <Stack1
          sx={{
            justifyContent: "center",
            margin: "auto",
            paddingBottom: "30px",
            paddingTop: "70px",
          }}
          spacing={4}
        >
          {loading ? (
            <Box sx={{ margin: "0 auto" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Stack spacing={0} sx={{ p: "0 18px" }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 32,
                    fontWeight: 700,
                    color: "#060A25",
                  }}
                >
                  User Info
                </Typography>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                alignItems="flex-start"
                padding="0 18px"
              >
                <Avatar
                  src={
                    user?.pictureFileName
                      ? getUrlBlobContainerImage(user.pictureFileName)
                      : defaultImage
                  }
                  sx={{ width: 100, height: 100 }}
                  variant="rounded"
                />
                <Box sx={{ flexGrow: 1 }} />
                {user?.isFollowing ? (
                  <>
                    <Button1
                      sx={{
                        padding: "16px 24px",
                        lineHeight: "120%",
                        background: "#2575FC",
                        textTransform: "capitalize",
                        fontWeight: 500,
                      }}
                      variant="contained"
                      color="primary"
                      onClick={unfollowItem}
                    >
                      Unfollow
                    </Button1>
                    {user?.IsFollowPending && (
                      <Ttt>
                        Your following request is waiting for the approve...
                      </Ttt>
                    )}
                  </>
                ) : (
                  <Button1
                    sx={{
                      padding: "16px 24px",
                      lineHeight: "120%",
                      background: "#2575FC",
                      textTransform: "capitalize",
                      fontWeight: 500,
                    }}
                    variant="contained"
                    color="primary"
                    onClick={followItem}
                  >
                    Follow
                  </Button1>
                )}
              </Stack>
              <Stack spacing={2} direction="row" alignItems="flex-start">
                <Grid
                  container
                  spacing={2}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                  padding="0 18px 0 0"
                >
                  <Grid item xs={6} width={"100%"}>
                    <Stack
                      spacing={1}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        First Name
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#060A25",
                          marginBottom: "28px",
                        }}
                      >
                        {user?.firstName}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} width={"100%"}>
                    <Stack
                      spacing={1}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Last Name
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#060A25",
                          marginBottom: "28px",
                        }}
                      >
                        {user?.lastName}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} width={"100%"}>
                    <Stack
                      spacing={1}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Email Address
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#060A25",
                          marginBottom: "28px",
                        }}
                      >
                        {user?.email}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} width={"100%"}>
                    <Stack
                      spacing={1}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Phone Number
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#060A25",
                          marginBottom: "28px",
                        }}
                      >
                        {user?.phoneNumber}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} width={"100%"}>
                    <Stack
                      spacing={1}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Address
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#060A25",
                          marginBottom: "28px",
                        }}
                      >
                        {user?.address}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}
          <Snackbar
            open={notificationShow && notificationText?.length !== 0}
            autoHideDuration={6000}
            onClose={handleNotificationClose}
          >
            <Alert
              onClose={handleNotificationClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {notificationText}
            </Alert>
          </Snackbar>
        </Stack1>
      )}
    </>
  );
};
export default UserDetail;
