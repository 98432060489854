import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import {
  AddRecommendationDto,
  ApiException,
  ApprovedBy,
  Campaign,
  CampaignClient,
  Category,
  EditUserDto,
  GroupAccountBalanceClient,
  GroupAccountBalanceDto,
  InvestmentType,
  RecommendationsClient,
  TokenDto,
  UsersClient,
} from "../../../utilities/backend/client";
import { useParams, useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useRef, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import Download from "../../../resources/download";
import PdfViewer from "../../../components/PdfViewer";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OnlyIfAuthenticated from "../../../state/currentUser/guards/OnlyIfAuthenticated";
import OnlyIfNotAuthenticated from "../../../state/currentUser/guards/OnlyIfNotAuthenticated";
import { currency_format } from "../../../utilities/helpers/currency";
import { ShareModal, ShareModalMain } from "./ShareModal";
import CloseIcon from "@mui/icons-material/Close";
import { getUrlBlobContainerImage } from "../../../resources/imageConstants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  borderColor: "#828282",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #828282",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#45B08A",
  },
}));

const InvestmentMainInfoDesk = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const InvestmentMainInfoMob = styled(Grid)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const TitleContainer = styled(Stack)(({ theme }) => ({
  padding: "0 16px",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    padding: 0,
    alignItems: "end",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 32,
  fontWeight: 700,
  color: "#191919",
  lineHeigh: "38.4px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "26px",
    lineHeight: "normal",
  },
}));

const MainImage = styled("img")(({ theme }) => ({
  height: "400px",
  width: "100%",
  objectFit: "cover",
  borderRadius: "8px",
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
}));

const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [theme.breakpoints.down("sm")]: {
    padding: "14px",
    p: {
      fontSize: "14px",
    },
  },
}));

const MainInfo = styled(Stack)(({ theme }) => ({
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const InvestmentButtons = styled(Stack)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  overflow: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "570px",
  },
}));

const InvestmentButton = styled(Button)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    ...(isSelected
      ? {
          color: "#474747",
          textTransform: "none",
          borderColor: "#474747",
          p: "13px 25px",
          fontSize: 16,
        }
      : {
          backgroundColor: "#45B08A",
          textTransform: "none",
          p: "13px 25px",
          fontSize: 16,
        }),
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
  })
);

const ContentTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 24,
  fontWeight: 700,
  color: "#191919",
  lineHeigh: "38.4px",
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    lineHeight: "120%",
  },
}));

const ContentText = styled("p")(({ theme }) => ({
  marginTop: "15px",
  marginBottom: "65px",
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 400,
  color: "#656565",
  lineHeight: "20.68px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "32px",
  },
}));

const Themes = styled(Stack)(({ theme }) => ({
  marginBottom: "56px !important",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "8px !important",
  },
}));

const SdGsItem = styled(Box)(({ theme }) => ({
  alignContent: "center",
  height: 120,
  width: 120,
  marginLeft: "0 !important",
  [theme.breakpoints.down("sm")]: {
    height: "initial",
    width: "30.33%",
  },
}));

const ShareButton = styled(Button)(({ theme }) => ({
  width: "100px",
  background: "#2575FC",
  textTransform: "capitalize",
  marginTop: "0 !important",
  [theme.breakpoints.down("sm")]: {
    width: "initial",
    background: "transparent !important",
    boxShadow: "none !important",
    span: {
      marginRight: "0 !important",
    },
    p: {
      display: "none",
    },
    svg: {
      color: "#191919",
    },
  },
}));

const InvestmentInProcessStyles = styled(Stack)(({ theme }) => ({
  marginBottom: "82px",
  minWidth: "1000px",
  [theme.breakpoints.down("sm")]: {
    minWidth: "initial",
    textAlign: "center",
    marginBottom: "32px",
  },
}));

const InvestmentInProcessTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 32,
  fontWeight: 700,
  color: "#191919",
  lineHeigh: "36.77px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

const InvestmentInProcessDescription = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 400,
  color: "#474747",
  lineHeigh: "19.2px",
}));

const BottomBlock = styled(Box)(({ theme }) => ({
  borderRadius: "14px",
  backgroundImage: "url(/share-page.png)",
  backgroundSize: "cover",
  padding: "60px 0 73px",
  marginBottom: "51px",
  [theme.breakpoints.down("sm")]: {
    padding: "60px 16px 73px",
  },
}));

const BottomTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 36,
  fontWeight: 700,
  color: "#fff",
  marginBottom: "8px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: 26,
  },
}));

const BottomDescription = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "120%",
  marginBottom: "25px",
  color: "#fff",
  textAlign: "center",
}));

const StyledInput = {
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  'input[type="number"]': {
    "-moz-appearance": "textfield",
  },
};

const InvestmentDetail = () => {
  const navigate = useNavigate();
  const campaignClient = new CampaignClient();
  const groupAccountBalanceClient = new GroupAccountBalanceClient();
  const userClient = new UsersClient();
  const recommendationsClient = new RecommendationsClient();
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const { identifier } = useParams();
  const [campain, setCampain] = useState<Campaign>({} as Campaign);
  const [groupAccountBalance, setGroupAccountBalanceClient] =
    useState<GroupAccountBalanceDto>({} as GroupAccountBalanceDto);
  const [user, setUser] = useState<EditUserDto>({} as EditUserDto);
  const [selectedButton, setSelectedButton] = useState(1);
  const [amount, setAmount] = useState("");
  const [inProcess, setInProcess] = useState(true);
  const [approvedBy, setApprovedBy] = useState<ApprovedBy[]>();
  const [investmentTypes, setInvestmentTypes] = useState<InvestmentType[]>();
  const [allThemes, setAllThemes] = useState<Category[]>();
  const aboutRef = useRef(null);
  const investDetRef = useRef(null);
  const [checked, setChecked] = useState(true);
  const [haveCost, setHaveCost] = useState(true);
  const [pdfBase64, setPdfBase64] = useState("");
  const [investmentInProcessShow, setInvestmentInProcessShow] =
    useState<boolean>(false);
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleModalOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setModalOpen(true);
    return false;
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (token.token) {
      userClient
        .users_GetUser(token)
        .then((res) => {
          setUser(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    Promise.all([
      campaignClient.campaign_GetCampaign(identifier!),
      campaignClient.campaign_GetData(),
      campaignClient.campaign_GetPdf(identifier!),
    ])
      .then((value) => {
        setCampain(value[0]);
        setInvestmentTypes(value[1].investmentType);
        setApprovedBy(value[1].approvedBy);
        setAllThemes(value[1].theme);
        setPdfBase64(value[2]);
        setInProcess(false);

        if (token.token) {
          groupAccountBalanceClient
            .group_GetGroupAccountBalanceByCampaignId(value[0].id!, token)
            .then((responce) => {
              setGroupAccountBalanceClient(responce);
            });
        }
      })
      .catch((error) => {
        const errorObject = JSON.parse(error.response);
        if (errorObject.status === 404) {
          navigate("/find");
        }
      });
  }, []);

  const imageSource = campain?.image || "";
  const themes = campain.themes?.split(",");
  const sdgs = campain.sdGs
    ?.split(",")
    ?.map(Number)
    .sort((a, b) => a - b);

  const handleAmountChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.target.value === "" ||
      regex.test(event.target.value.toString())
    ) {
      setAmount(event.target.value);
      const balance =
        (groupAccountBalance?.balance ?? 0) + (user?.accountBalance ?? 0);
      if (Number(event.target.value) <= balance) {
        setHaveCost(true);
      } else {
        setHaveCost(false);
      }
    }
  };

  function changeActiveButton(select: number) {
    setSelectedButton(select);
    let element = document.getElementById(select.toString());
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const downloadPdf = () => {
    var link = document.createElement("a");
    link.download = "PDF " + campain.name + ".pdf";
    link.href = pdfBase64!;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function themeName(themeId: string) {
    const thisTheme = allThemes!.filter(
      (theme) => theme && theme.id === Number(themeId)
    );
    return thisTheme[0].name;
  }

  const createRecommendation = async (
    userData: EditUserDto,
    amountToGive: string,
    campaign: Campaign
  ) => {
    if (haveCost) {
      setInProcess(true);
      let recommendationData: AddRecommendationDto = {
        amount: Number(amountToGive),
        campaignId: campaign.id,
        userEmail: userData.email,
        userFullName: `${userData.firstName} ${userData.lastName}`,
        isGroupAccountBalance: !!groupAccountBalance,
      };

      recommendationsClient
        .recommendations_CreateRecommendation(recommendationData)
        .then(async () => {
          setInProcess(false);
          setInvestmentInProcessShow(true);
          setNotificationText(`The Recommendation was successfully created!`);
          setNotificationShow(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate("/fund-investment");
    }
  };

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  function findApprovedByByIds(idsToFind: string): string {
    if (!idsToFind) return "";

    const idArray = idsToFind.split(",").map((idStr) => parseInt(idStr, 10));
    const matchingApprovedBy = approvedBy!.filter(
      (a) => a && idArray.includes(a.id!)
    );
    const names = matchingApprovedBy.map((a) => a.name).filter(Boolean);
    return names.join(", ");
  }

  function findInvestmentTypesByIds(idsToFind: string): string {
    const idArray = idsToFind.split(",").map((idStr) => parseInt(idStr, 10));
    const matchingInvestmentTypes = investmentTypes!.filter(
      (investmentType) => investmentType && idArray.includes(investmentType.id!)
    );
    const names = matchingInvestmentTypes
      .map((investmentType) => investmentType.name)
      .filter(Boolean);
    return names.join(", ");
  }

  const barValue =
    (Number(campain.currentBalance) * 100) / Number(campain.target);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleClick = () => {
    window.location.href = `mailto:${campain.contactInfoEmailAddress}`;
  };

  const investmentMainInfo = (
    <Box
      sx={{ border: "solid 1px #E8E8E8", borderRadius: "8px", padding: "15px" }}
    >
      <Stack spacing={1}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 18,
                fontWeight: 700,
                color: "#191919",
              }}
            >
              Fund this Investment
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BorderLinearProgress
              variant="determinate"
              value={barValue > 100 ? 100 : barValue}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={10} sx={{ marginBottom: "12px" }}>
            <Stack direction={"row"} spacing={1}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 26,
                  fontWeight: 700,
                  color: "#191919",
                  lineHeigh: "29.87px",
                }}
              >
                {currency_format(
                  campain.currentBalance === null
                    ? 0
                    : Number(campain.currentBalance)
                )}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  fontWeight: 400,
                  color: "#656565",
                  paddingTop: "10px",
                }}
              >
                raised to date
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={10} sx={{ marginBottom: "25px" }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 18,
                fontWeight: 400,
                color: "#656565",
                lineHeigh: "22px",
              }}
            >
              of a {currency_format(Number(campain.target))} goal
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <Typography
              width={"100%"}
              align="left"
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 16,
                fontWeight: 400,
                color: "#656565",
                lineHeigh: "20.68px",
                width: "100%",
                alignContent: "start",
              }}
            >
              Number of Investors
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              align="right"
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 16,
                fontWeight: 700,
                color: "#191919",
                lineHeigh: "20.68px",
                width: "100%",
                alignContent: "end",
              }}
            >
              {campain.numberOfInvestors}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          sx={{
            paddingTop: "17px",
            alignItems: "center",
            marginBottom: "15px !important",
          }}
        >
          <Grid item xs={8}>
            <Stack spacing={0} direction={"row"}>
              <Typography
                align="left"
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  fontWeight: 700,
                  color: "#191919",
                  lineHeigh: "20.68px",
                  width: "100%",
                }}
              >
                Invest Now:
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  fontWeight: 400,
                  color: "#828282",
                  lineHeigh: "20.68px",
                  width: "100%",
                  alignContent: "start",
                }}
              >
                {"(Min " +
                  currency_format(Number(campain.minimumInvestment)) +
                  ")"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} width={"100%"}>
            <TextField
              type="number"
              value={amount}
              onChange={handleAmountChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              sx={{ ...StyledInput }}
            ></TextField>
          </Grid>
        </Grid>
        {haveCost ? (
          <Typography
            width={"100%"}
            align="left"
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 14,
              fontWeight: 400,
              color: "#FFFFFF",
              alignContent: "start",
            }}
          >
            ㅤ
          </Typography>
        ) : (
          <Typography
            width={"100%"}
            align="left"
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 14,
              fontWeight: 400,
              color: "#FF0000",
              alignContent: "start",
            }}
          >
            There are not sufficient funds in your account to make this
            investment. You can add funds on&nbsp;
            <a
              href="https://catacap.org/#donatetoinvest"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donate
            </a>
            .
          </Typography>
        )}
        <Grid container spacing={0}>
          <OnlyIfAuthenticated
            children={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  <Typography>
                    I accept &nbsp;
                    <a onClick={handleModalOpen} href="#">
                      Investment Terms
                    </a>
                  </Typography>
                }
              />
            }
          />
        </Grid>
        <Stack>
          <OnlyIfAuthenticated
            children={
              <Button
                disabled={
                  Number(amount) < Number(campain.minimumInvestment!) ||
                  !checked
                }
                variant="contained"
                sx={{
                  width: "100%",
                  margin: "auto",
                  textTransform: "none",
                  backgroundColor: "#2575FC",
                  py: "13px",
                }}
                onClick={() => {
                  createRecommendation(user, amount, campain);
                }}
              >
                Invest Now
              </Button>
            }
          />
          <OnlyIfNotAuthenticated
            children={
              <Button
                variant="contained"
                sx={{
                  width: "90%",
                  margin: "auto",
                  textTransform: "none",
                  backgroundColor: "#2575FC",
                  py: "13px",
                }}
                onClick={() => {
                  navigate("/register");
                }}
              >
                Learn More
              </Button>
            }
          />
        </Stack>
        <Typography
          width={"100%"}
          align="left"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 14,
            fontWeight: 400,
            color: "#656565",
            alignContent: "start",
            paddingTop: "20px",
          }}
        >
          Questions about investment?
        </Typography>
        <Typography
          width={"100%"}
          align="left"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 14,
            fontWeight: 400,
            color: "#45B08A",
            alignContent: "start",
            paddingBottom: "20px",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {"Contact " + campain.name}
        </Typography>
      </Stack>
    </Box>
  );

  const InvestmentInProcess = ({ backClick }: any) => {
    return (
      <div>
        <InvestmentInProcessStyles spacing={4}>
          <Button onClick={backClick} sx={{ width: "80px" }}>
            <ArrowBackIcon sx={{ color: "000000" }}></ArrowBackIcon>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 16,
                fontWeight: 400,
                color: "#474747",
              }}
            >
              Back
            </Typography>
          </Button>
          <Stack margin={"auto"} alignItems={"center"}>
            <InvestmentInProcessTitle>
              Your investment is in process!
            </InvestmentInProcessTitle>
            <InvestmentInProcessDescription>
              You will be notified when your investment is finalized.
            </InvestmentInProcessDescription>
          </Stack>
        </InvestmentInProcessStyles>
        <BottomBlock>
          <BottomTitle>Amplify Your Impact</BottomTitle>
          <BottomDescription>
            Invite others to follow your lead!
          </BottomDescription>
          <ShareModalMain campaign={campain} />
        </BottomBlock>
      </div>
    );
  };

  return (
    <div>
      <ShareModal open={open} handleClose={handleClose} campaign={campain} />
      <Container sx={{ paddingTop: "70px", paddingBottom: "30px" }}>
        <Content>
          {investmentInProcessShow ? (
            <InvestmentInProcess
              backClick={() => setInvestmentInProcessShow(false)}
            />
          ) : (
            <MainInfo spacing={2}>
              {!inProcess ? (
                <>
                  <TitleContainer spacing={2}>
                    <Title>{"Invest in " + campain.name}</Title>
                    <ShareButton
                      onClick={handleOpen}
                      variant="contained"
                      startIcon={<ShareIcon />}
                    >
                      <p>Share</p>
                    </ShareButton>
                  </TitleContainer>
                  <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                    <Grid item xs={12} md={8}>
                      <div>
                        <MainImage
                          src={getUrlBlobContainerImage(
                            campain?.imageFileName!
                          )}
                          alt="Logo"
                        />
                      </div>
                      <InvestmentMainInfoMob
                        item
                        sx={{
                          flex: "100% 0",
                          flexShrink: 0,
                          marginTop: "30px",
                        }}
                      >
                        {investmentMainInfo}
                      </InvestmentMainInfoMob>
                      <div style={{ maxWidth: "100%", overflow: "auto" }}>
                        <InvestmentButtons
                          spacing={2}
                          margin={"auto"}
                          direction="row"
                        >
                          <InvestmentButton
                            variant={
                              selectedButton == 1 ? "contained" : "outlined"
                            }
                            color={selectedButton == 1 ? "success" : "inherit"}
                            isSelected={selectedButton != 1}
                            onClick={() => {
                              changeActiveButton(1);
                            }}
                          >
                            About
                          </InvestmentButton>
                          <InvestmentButton
                            variant={
                              selectedButton == 2 ? "contained" : "outlined"
                            }
                            color={selectedButton == 2 ? "success" : "inherit"}
                            isSelected={selectedButton != 2}
                            onClick={() => {
                              changeActiveButton(2);
                            }}
                          >
                            Investment Details
                          </InvestmentButton>
                          <InvestmentButton
                            variant={
                              selectedButton == 3 ? "contained" : "outlined"
                            }
                            color={selectedButton == 3 ? "success" : "inherit"}
                            isSelected={selectedButton != 3}
                            onClick={() => {
                              changeActiveButton(3);
                            }}
                          >
                            Themes
                          </InvestmentButton>
                          <OnlyIfAuthenticated>
                            <InvestmentButton
                              variant={
                                selectedButton == 4 ? "contained" : "outlined"
                              }
                              color={
                                selectedButton == 4 ? "success" : "inherit"
                              }
                              isSelected={selectedButton != 4}
                              onClick={() => {
                                changeActiveButton(4);
                              }}
                            >
                              Investment Materials
                            </InvestmentButton>
                          </OnlyIfAuthenticated>
                        </InvestmentButtons>
                      </div>
                      <ContentTitle id="1" ref={aboutRef}>
                        About {campain.name}
                      </ContentTitle>
                      <ContentText
                        dangerouslySetInnerHTML={{
                          __html: campain.description
                            ? campain.description
                            : "",
                        }}
                      ></ContentText>
                      {/* <Typography sx={{ marginTop: "15px", marginBottom: "65px", fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 400, color: "#656565", lineHeigh: '20.68px' }}>{campain.description}</Typography> */}
                      <Typography
                        id="2"
                        ref={investDetRef}
                        sx={{
                          marginBottom: "15px",
                          fontFamily: "Ubuntu",
                          fontSize: 24,
                          fontWeight: 700,
                          color: "#191919",
                          lineHeigh: "38.4px",
                          marginTop: "40px",
                        }}
                      >
                        Investment Details
                      </Typography>
                      <Stack spacing={2} margin={"auto"}>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow
                                sx={{
                                  backgroundColor: token.token
                                    ? "none "
                                    : "#F2F2F2",
                                  borderRadius: "8px",
                                  border: "0px solid white",
                                }}
                              >
                                <StyledTableCell sx={{ borderBottom: "none" }}>
                                  <Typography
                                    sx={{
                                      fontFamily: "Ubuntu",
                                      fontSize: 16,
                                      fontWeight: 400,
                                      color: "#656565",
                                      lineHeigh: "20.68px",
                                      width: "100%",
                                      alignContent: "start",
                                    }}
                                  >
                                    Terms
                                  </Typography>
                                </StyledTableCell>
                                {token.token ? (
                                  <StyledTableCell
                                    sx={{ borderBottom: "none" }}
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: campain.terms
                                          ? campain.terms
                                          : "",
                                      }}
                                      style={{
                                        fontFamily: "Ubuntu",
                                        fontSize: 16,
                                        fontWeight: 700,
                                        color: "#191919",
                                        lineHeight: "20.68px",
                                        width: "100%",
                                      }}
                                    ></p>
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell
                                    sx={{ borderBottom: "none" }}
                                  >
                                    <p
                                      style={{
                                        fontFamily: "Ubuntu",
                                        fontSize: 16,
                                        fontWeight: 700,
                                        color: "#191919",
                                        lineHeight: "20.68px",
                                        width: "100%",
                                      }}
                                    >
                                      In order to see Terms, you need a &nbsp;
                                      <a
                                        href="https://app.catacap.org/register"
                                        style={{
                                          color: "#007BFF",
                                          textDecoration: "none",
                                        }}
                                      >
                                        CataCap account
                                      </a>
                                      , or to be&nbsp;
                                      <a
                                        href="https://app.catacap.org/home"
                                        style={{
                                          color: "#007BFF",
                                          textDecoration: "none",
                                        }}
                                      >
                                        logged in
                                      </a>
                                      .
                                    </p>
                                  </StyledTableCell>
                                )}
                              </TableRow>
                              <OnlyIfNotAuthenticated
                                children={
                                  <TableRow
                                    sx={{
                                      backgroundColor: user.email
                                        ? "#F2F2F2"
                                        : "none",
                                      borderRadius: "8px",
                                      border: "0px solid white",
                                    }}
                                  >
                                    <StyledTableCell
                                      sx={{ borderBottom: "none" }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Ubuntu",
                                          fontSize: 16,
                                          fontWeight: 400,
                                          color: "#656565",
                                          lineHeigh: "20.68px",
                                          width: "100%",
                                          alignContent: "start",
                                        }}
                                      >
                                        Investment Type
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{ borderBottom: "none" }}
                                    >
                                      <Typography
                                        align="right"
                                        sx={{
                                          fontFamily: "Ubuntu",
                                          fontSize: 16,
                                          fontWeight: 700,
                                          color: "#191919",
                                          lineHeigh: "20.68px",
                                          width: "100%",
                                          alignContent: "end",
                                        }}
                                      >
                                        {!inProcess &&
                                          findInvestmentTypesByIds(
                                            campain.investmentTypes!
                                          )}
                                      </Typography>
                                    </StyledTableCell>
                                  </TableRow>
                                }
                              ></OnlyIfNotAuthenticated>
                              <TableRow
                                sx={{
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "8px",
                                  border: "0px solid white",
                                }}
                              >
                                <StyledTableCell sx={{ borderBottom: "none" }}>
                                  <Typography
                                    sx={{
                                      fontFamily: "Ubuntu",
                                      fontSize: 16,
                                      fontWeight: 400,
                                      color: "#656565",
                                      lineHeigh: "20.68px",
                                      width: "100%",
                                      alignContent: "start",
                                    }}
                                  >
                                    Minimum Investment
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ borderBottom: "none" }}>
                                  <Typography
                                    align="right"
                                    sx={{
                                      fontFamily: "Ubuntu",
                                      fontSize: 16,
                                      fontWeight: 700,
                                      color: "#191919",
                                      lineHeigh: "20.68px",
                                      width: "100%",
                                      alignContent: "end",
                                    }}
                                  >
                                    {currency_format(
                                      Number(campain.minimumInvestment)
                                    )}
                                  </Typography>
                                </StyledTableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  borderRadius: "8px",
                                  border: "0px solid white",
                                }}
                              >
                                <StyledTableCell sx={{ borderBottom: "none" }}>
                                  <Typography
                                    sx={{
                                      fontFamily: "Ubuntu",
                                      fontSize: 16,
                                      fontWeight: 400,
                                      color: "#656565",
                                      lineHeigh: "20.68px",
                                      width: "100%",
                                      alignContent: "start",
                                    }}
                                  >
                                    Website
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ borderBottom: "none" }}>
                                  <Typography
                                    align="right"
                                    sx={{
                                      fontFamily: "Ubuntu",
                                      fontSize: 16,
                                      fontWeight: 700,
                                      color: "#191919",
                                      lineHeigh: "20.68px",
                                      width: "100%",
                                      alignContent: "end",
                                    }}
                                  >
                                    {campain.website}
                                  </Typography>
                                </StyledTableCell>
                              </TableRow>
                              <OnlyIfAuthenticated
                                children={
                                  <TableRow
                                    sx={{
                                      backgroundColor: "#F2F2F2",
                                      borderRadius: "8px",
                                      border: "0px solid white",
                                    }}
                                  >
                                    <StyledTableCell
                                      sx={{ borderBottom: "none" }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Ubuntu",
                                          fontSize: 16,
                                          fontWeight: 400,
                                          color: "#656565",
                                          lineHeigh: "20.68px",
                                          width: "100%",
                                          alignContent: "start",
                                        }}
                                      >
                                        Sourced By
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{ borderBottom: "none" }}
                                    >
                                      <Typography
                                        align="right"
                                        sx={{
                                          fontFamily: "Ubuntu",
                                          fontSize: 16,
                                          fontWeight: 700,
                                          color: "#191919",
                                          lineHeigh: "20.68px",
                                          width: "100%",
                                          alignContent: "end",
                                        }}
                                      >
                                        {!inProcess &&
                                          findApprovedByByIds(
                                            campain.approvedBy!
                                          )}
                                      </Typography>
                                    </StyledTableCell>
                                  </TableRow>
                                }
                              />
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Typography
                          id="3"
                          sx={{
                            fontFamily: "Ubuntu",
                            fontSize: 24,
                            fontWeight: 700,
                            color: "#191919",
                            lineHeigh: "38.4px",
                            paddingTop: "65px",
                          }}
                        >
                          Themes & SDGs supported
                        </Typography>
                        <Themes direction={"row"} spacing={2}>
                          {themes?.map((theme) => (
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              spacing={2}
                            >
                              <Box
                                alignContent={"center"}
                                key={theme}
                                component="img"
                                alt={theme}
                                src={"/theme" + theme + ".jpg"}
                                height={"150px"}
                                width={"150px"}
                                sx={{ borderRadius: "12px" }}
                              />
                              <Typography
                                sx={{
                                  fontFamily: "Ubuntu",
                                  fontSize: 18,
                                  fontWeight: 700,
                                  color: "#474747",
                                  lineHeigh: "38.4px",
                                }}
                              >
                                {themeName(theme)}
                              </Typography>
                            </Stack>
                          ))}
                        </Themes>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          sx={{ flexWrap: "wrap", gap: "16px" }}
                        >
                          {sdgs?.map((sdg) => (
                            <SdGsItem
                              key={sdg}
                              component="img"
                              // @ts-ignore
                              alt={sdg}
                              src={"/sdg" + sdg + ".jpg"}
                            />
                          ))}
                        </Stack>
                        {pdfBase64 !== "" && (
                          <>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                paddingTop: "65px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "16px !important",
                              }}
                            >
                              <Grid item sx={{ padding: "0 !important" }}>
                                <Typography
                                  id="4"
                                  sx={{
                                    fontFamily: "Ubuntu",
                                    fontSize: 24,
                                    fontWeight: 700,
                                    color: "#191919",
                                    lineHeigh: "38.4px",
                                  }}
                                >
                                  Investment Materials
                                </Typography>
                              </Grid>
                              {token.token && (
                                <Grid item sx={{ padding: "0 !important" }}>
                                  <Box
                                    sx={{
                                      height: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      margin: "auto",
                                    }}
                                  >
                                    <Button
                                      onClick={downloadPdf}
                                      variant="contained"
                                      startIcon={<Download />}
                                      sx={{
                                        backgroundColor: "#45B08A",
                                        width: "185px",
                                      }}
                                    >
                                      Download PDF
                                    </Button>
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                            {token.token ? (
                              <PdfViewer pdfBase64={pdfBase64} />
                            ) : (
                              <p
                                style={{
                                  fontFamily: "Ubuntu",
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: "#191919",
                                  lineHeight: "20.68px",
                                  width: "100%",
                                }}
                              >
                                In order to see Investment Materials, you need
                                a&nbsp;
                                <a
                                  href="https://app.catacap.org/register"
                                  style={{
                                    color: "#007BFF",
                                    textDecoration: "none",
                                  }}
                                >
                                  CataCap account
                                </a>
                                , or to be&nbsp;
                                <a
                                  href="https://app.catacap.org/home"
                                  style={{
                                    color: "#007BFF",
                                    textDecoration: "none",
                                  }}
                                >
                                  logged in
                                </a>
                                .
                              </p>
                            )}
                          </>
                        )}
                      </Stack>
                    </Grid>
                    <InvestmentMainInfoDesk
                      item
                      sx={{ flex: "360px 0", flexShrink: 0 }}
                    >
                      {investmentMainInfo}
                    </InvestmentMainInfoDesk>
                  </Grid>
                </>
              ) : (
                <CircularProgress />
              )}
            </MainInfo>
          )}
        </Content>
        <Snackbar
          open={notificationShow && notificationText.length !== 0}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
        >
          <Alert
            onClose={handleNotificationClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {notificationText}
          </Alert>
        </Snackbar>
      </Container>

      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={modalOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Investment Terms
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            You are submitting a recommendation to the CataCap Impact Investing
            and Grantmaking Advisory Council (the Council) of CataCap to
            consider the opportunity described (the Opportunity). You understand
            that this recommendation does not involve the evaluation of a
            purchase or sale of securities or any assessment of the merits of an
            investment in the Opportunity. You understand that if your
            recommendation is accepted by the Council, the Opportunity will be
            considered by CataCap for an investment or grant recommendation that
            is transmitted to IA from time to time when enough scale has been
            reached to be practical or meet required investment or grant
            minimums. Although expected to be infrequent and unlikely,
            allocations that are recommended by the Council may not be executed
            due to a variety of factors (e.g., missed or changed investment
            closing timing, failure to meet a minimum, legal issues, etc.), in
            which case any funds allocated to such Opportunity will be returned
            to the Impact Liquidity Balances of the respective donor accounts
            from which such Opportunity was recommended.
          </Typography>
          <Typography
            gutterBottom
            sx={{
              mt: "15px",
            }}
          >
            In making this investment recommendation, you stipulate that you are
            not a&nbsp;
            <a
              href="https://www.irs.gov/charities-non-profits/irc-section-4946-definition-of-disqualified-person"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 700, textDecoration: "underline" }}
            >
              disqualified person
            </a>
            , an employee, compensated advisor, shareholder or board member of
            the investment entity. Furthermore, in making any investment
            recommendation you stipulate that the holdings of a given donor’s
            CataCap donor account combined with any personal holdings in a
            business enterprise may not exceed 20% of the voting stock of an
            incorporated business, 20% of the profit interest of a partnership
            or joint venture or the beneficial interest of a trust or similar
            entity, or any interest in a sole proprietorship. In the case of
            Fund or Special Purpose Vehicle (SPV), those limits apply to the
            portfolio companies or target company, and not to the Fund or SPV
            itself.
          </Typography>
          <Typography
            gutterBottom
            sx={{
              mt: "15px",
            }}
          >
            <span style={{ fontWeight: 700, textDecoration: "underline" }}>
              NOTE: IF THIS IS A GRANT
            </span>
            (versus an investment) recommendation, as clearly noted in the
            Opportunity description, you understand that this is a
            recommendation to allocate funds from your donor account that will
            become part of a grant recommendation to a 501(c)(3) public charity
            and is&nbsp;
            <span style={{ textDecoration: "underline" }}>
              NOT a recoverable investment
            </span>
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose}>
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default InvestmentDetail;
