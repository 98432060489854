import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Chart } from "react-chartjs-2";
import {
  Campaign,
  CampaignClient,
  Category,
  RecommendationsDto,
} from "../../../utilities/backend/client";
import CampaignCard from "../../../components/CampaignCard";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartData,
  ChartArea,
  Filler,
  registerables as registerablesjs,
} from "chart.js";
import { currency_format } from "../../../utilities/helpers/currency";
ChartJS.register(...registerablesjs);

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

const StackRes1 = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  margin: "0 auto",
  marginTop: "80px",
  height: "100%",
  mb: "50px",
  width: "1280px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    overflow: "hidden",
  },
}));
const BoxR1 = styled(Box)(({ theme }) => ({
  marginLeft: "15rem",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0rem",
  },
}));
const TypographyR = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const Portfolio = () => {
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<"bar">>({
    datasets: [],
  });

  const [numberOfInvestments, setNumberOfInvestments] = useState(0);
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState(0);
  const [accountBalance, setAccountBalance] = useState<number>(0);

  const [sdgs, setSdgs] = useState<string[]>([]);
  const [themes, setThemes] = useState<string[]>([]);
  const [allThemes, setAllThemes] = useState<Category[]>();
  const campaignClient = new CampaignClient();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [rec, setRec] = useState<RecommendationsDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingChart, setLoadingChart] = useState<boolean>(true);

  const onlyUnique = (value: string, index: number, array: string[]) => {
    return array.indexOf(value) === index;
  };

  function createGradient(
    ctx: CanvasRenderingContext2D | null,
    area: ChartArea | undefined
  ) {
    if (!ctx || !area) return "transparent";
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, "white");
    gradient.addColorStop(0.3, "#45B08A");
    gradient.addColorStop(1, "#45B08A");

    return gradient;
  }

  function themeName(themeId: string) {
    if (allThemes && themeId !== "") {
      const thisTheme = allThemes!.filter(
        (theme) => theme && theme.id === Number(themeId)
      );
      return thisTheme[0].name;
    }
  }

  useEffect(() => {
    campaignClient
      .campaign_GetData()
      .then((r) => setAllThemes(r.theme))
      .catch((err) => console.log(err));

    campaignClient
      .campaign_GetPortfolio()
      .then((portfolio) => {
        if (portfolio.accountBalance)
          setAccountBalance(portfolio.accountBalance);
        const sdgs = portfolio.campaigns
          ?.map((i) => i.sdGs)
          .join(",")
          .split(",")
          .filter(onlyUnique)
          .map(Number)
          .sort((a, b) => a - b)
          .map(String);

        if (sdgs) setSdgs(sdgs);
        const themes = portfolio.campaigns
          ?.map((i) => i.themes)
          .join(",")
          .split(",")
          .filter(onlyUnique);
        if (themes) setThemes(themes);

        const sum = portfolio.recommendations
          ?.map((i) => i.amount)
          .reduce((prev, next) => (prev && next ? prev + next : 0));
        if (sum) setTotalInvestmentAmount(sum);
        const number = portfolio.recommendations?.length;
        if (number) setNumberOfInvestments(number);
        if (portfolio.campaigns) setCampaigns(portfolio.campaigns);
        if (portfolio.recommendations) setRec(portfolio.recommendations);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const chartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    rec?.map((i) => {
      if (i.dateCreated) {
        const index = new Date(i.dateCreated).getMonth();
        const newValue = i.amount ? i.amount : 0;
        chartData[index] += newValue;
      } else return i;
    });

    for (let it = 0; it < chartData.length; it++) {
      if (chartData[it] > chartData[it + 1])
        chartData[it + 1] = chartData[it + 1] + chartData[it];
    }

    const chart = chartRef.current;

    const labels = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const data = {
      labels,
      datasets: [
        {
          data: chartData,
          backgroundColor: chart
            ? createGradient(chart.ctx, chart.chartArea)
            : "#45B08A",
          fill: true,
          label: "My Investments",
        },
      ],
    };
    setChartData(data);
    setLoadingChart(false);
  }, [rec]);

  const o = {
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return "$" + value;
          },
        },
      },
    },
  };

  const themes2 = ["Cliname", "Gender", "Racial", "Poverty"];

  return (
    <StackRes1 spacing={4}>
      {loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", margin: "0 auto" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack sx={{ width: "100%", padding: "0 18px" }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 32,
                fontWeight: 700,
                color: "#060A25",
              }}
            >
              My CataCap Donor Account Portfolio
            </Typography>
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
              }}
            >
              <Divider
                sx={{
                  backgroundColor: "#45B08A",
                  height: "2px",
                  width: "135px",
                }}
              ></Divider>
              <Divider
                sx={{
                  backgroundColor: "#E8E8E8",
                  height: "2px",
                  width: "100%",
                }}
              ></Divider>
            </Stack>
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: "30px",
              padding: "0 18px",
            }}
          >
            <Box>
              <TypographyR
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 17,
                  fontWeight: 400,
                  color: "#656565",
                  lineHeigh: "20.68px",
                }}
              >
                Total amount You've Invested
              </TypographyR>
              <Typography
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 26,
                  fontWeight: 700,
                  color: "#191919",
                  lineHeigh: "29.87px",
                }}
              >
                {currency_format(totalInvestmentAmount)}
              </Typography>
            </Box>
            <BoxR1>
              <TypographyR
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 17,
                  fontWeight: 400,
                  color: "#656565",
                  lineHeigh: "20.68px",
                }}
              >
                Number of Investments
              </TypographyR>
              <Typography
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 26,
                  fontWeight: 700,
                  color: "#191919",
                  lineHeigh: "29.87px",
                }}
              >
                {numberOfInvestments}
              </Typography>
            </BoxR1>
            <BoxR1>
              <TypographyR
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 17,
                  fontWeight: 400,
                  color: "#656565",
                  lineHeigh: "20.68px",
                }}
              >
                Current Account Balance
              </TypographyR>
              <Typography
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 26,
                  fontWeight: 700,
                  color: "#191919",
                  lineHeigh: "29.87px",
                }}
              >
                {currency_format(accountBalance)}
              </Typography>
            </BoxR1>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "solid 3px #E8E8E8",
              mt: "40px",
              padding: "0 18px",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 26,
                fontWeight: 700,
                color: "#191919",
                lineHeigh: "29.87px",
              }}
            >
              My Investments
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              mt: "25px",
              padding: "0 18px",
            }}
          >
            {rec && rec.length > 0 ? (
              rec.map((i) => {
                const c = campaigns.find(
                  (x) => x.id == i.campaignId && !x.groupForPrivateAccessDto
                );
                return (
                  <Grid item>
                    <Link
                      to={
                        c?.property
                          ? `/invest/${c?.property}`
                          : `/invest/${c?.id}`
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <CampaignCard
                        campaign={c}
                        investedAmount={i.amount?.toString()}
                        investedDate={i.dateCreated?.toString()}
                        status={i.status?.toString()}
                      />
                    </Link>
                  </Grid>
                );
              })
            ) : (
              <Stack width="100%">
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#656565",
                    lineHeigh: "20.68px",
                  }}
                >
                  No Investments yet
                </Typography>
              </Stack>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "solid 3px #E8E8E8",
              mt: "40px",
              mb: "40px",
              padding: "0 18px",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 26,
                fontWeight: 700,
                color: "#191919",
                lineHeigh: "29.87px",
              }}
            >
              Themes & SDGs you are supporting
            </Typography>
          </Box>
          <Box sx={{ padding: "0 18px" }}>
            {themes[0] != "" ||
            (sdgs[0] != "0" && allThemes && allThemes.length > 0) ? (
              <>
                <Stack direction={"row"} spacing={2} marginBottom="20px">
                  {themes[0] != "" &&
                    themes.map((theme) => (
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        spacing={2}
                      >
                        <Box
                          alignContent={"center"}
                          key={theme}
                          component="img"
                          alt={theme}
                          src={"/theme" + theme + ".jpg"}
                          height={"150px"}
                          width={"150px"}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Ubuntu",
                            fontSize: 18,
                            fontWeight: 700,
                            color: "#474747",
                            lineHeigh: "38.4px",
                          }}
                        >
                          {themeName(theme)}
                        </Typography>
                      </Stack>
                    ))}
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  {sdgs[0] != "0" &&
                    sdgs?.map((sdg) => (
                      <Box
                        key={sdg}
                        sx={{ marginRight: "10px", marginBottom: "10px" }}
                      >
                        <img
                          alt={sdg}
                          src={`/sdg${sdg}.jpg`}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    ))}
                </Box>
              </>
            ) : (
              <Stack width="100%">
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#656565",
                    lineHeigh: "20.68px",
                  }}
                >
                  No Themes or SDGs yet
                </Typography>
              </Stack>
            )}
          </Box>

          {/* {rec && rec.length > 0 && */}
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "solid 3px #E8E8E8",
                mt: "40px",
                mb: "40px",
                padding: "0 18px",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 26,
                  fontWeight: 700,
                  color: "#191919",
                  lineHeigh: "29.87px",
                }}
              >
                Statistics
              </Typography>
            </Box>

            <Box sx={{ padding: "0 18px" }}>
              {loadingChart ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : chartData.datasets &&
                chartData.datasets[0].data.every((value: any) => {
                  return typeof value === "number" && value === 0;
                }) ? (
                <Box sx={{ padding: "0 " }}>
                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#656565",
                      lineHeigh: "20.68px",
                    }}
                  >
                    Make your first&nbsp;
                    <a
                      href="https://catacap.org/#donatetoinvest"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      investment
                    </a>
                    &nbsp;now.
                  </Typography>
                </Box>
              ) : (
                <Chart
                  ref={chartRef}
                  data={chartData}
                  type="line"
                  options={o}
                />
              )}
            </Box>
          </>
        </>
      )}
    </StackRes1>
  );
};

export default Portfolio;
